<!--
  @name: 查询到的会员信息卡片
-->
<template>
  <div class="info-card flex-center">
    <div class="flex-start align-center">
      <div class="mr-2x">
        <p class="font-l bold">{{ memberInfo.name }}</p>
        <p class="mv-1x gray-text font-s text-1" style="width:106px;">
          {{ memberInfo.currentLevelName }}
        </p>
        <p class="gray-text font-s" v-if="memberInfo.mobile">
          手机号：{{
            memberInfo.mobile.substr(0, 3) +
              "****" +
              memberInfo.mobile.substr(7, 11)
          }}
        </p>
        <div
          class="change-member gray-text font-s mt-2x pointer"
          @click="changeMember"
        >
          更换会员
        </div>
      </div>
      <a-avatar
        :size="86"
        :src="
          memberInfo.photo ||
            'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
        "
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "MemberInfoBasic",
  computed: {
    ...mapGetters("common", ["memberInfo"])
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("common", ["updateMember"]),
    changeMember() {
      this.updateMember({ id: "" });
      this.$emit("changeMember");
    }
  }
};
</script>

<style lang="less" scoped>
/*会员信息卡片*/
.info-card {
  position: relative;
  width: 250px;
  height: 136px;
  background: #ffffff;
  box-shadow: 0 0 9px 0 rgba(116, 116, 116, 0.22);
  border-radius: 14px;
  margin-right: 24px;
  overflow: hidden;
  background: url("../../../assets/images/ka1.png") no-repeat center center;
  background-size: cover;
  .change-member {
    width: 70px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border: 1px solid #535353;
    border-radius: 12px;
  }
  .gray-text {
    color: #666;
  }
}
</style>
