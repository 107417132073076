<!--
  @name: 会员折扣
  @author: fengyanlong
  @date:2021-04-14
-->
<template>
  <div class="order-hyzk">
    <!-- 当没有会员时，查询会员键盘 -->
    <div v-if="!memberId">
      <find-member @success="getInfo"></find-member>
    </div>
    <!--查找到会员-->
    <div v-if="memberId" class="flex-start">
      <!--会员信息卡片-->
      <member-info-basic @changeMember="reset"></member-info-basic>
      <!--会员等级卡片-->
      <div
        class="level-card white ph-2x pv-1x pointer"
        :style="'background-image:url(' + levelInfo.levelCardFace + ')'"
        @click="handleClick"
      >
        <div class="font-s text-r text-1">
          {{ memberInfo.currentLevelName }}
        </div>
        <div class="bold font-xxl ">
          <span v-if="levelInfo.counsumeDiscount == 1">无折扣</span>
          <span v-else>消费{{ levelInfo.discountRate / 10 }}折</span>
        </div>
        <div class="mt-1x">
          积分特权：
          <span v-if="levelInfo.integralMultiple > 100">
            {{ levelInfo.integralMultiple / 100 }} 倍积分
          </span>
          <span v-if="levelInfo.integralMultiple === 100">
            无倍数
          </span>
        </div>
        <div class="font-s mt-2x" v-if="memberInfo.privilegeEndDate">
          <span>有效期：</span>
          <span v-if="levelInfo.levelType == 1">永久有效</span>
          <span v-if="levelInfo.levelType == 2">
            {{ memberInfo.privilegeStartDate | date }} ~
            {{ memberInfo.privilegeEndDate | date }}
          </span>
        </div>
        <div class="checked" v-show="isChecked">
          <a-icon type="check" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FindMember from "../..//member/FindMember.vue";
import { mapGetters } from "vuex";
import { memberLevel } from "../../../api/member";
import MemberInfoBasic from "./MemberInfoBasic";
import { levelDiscount } from "../../../api/member";

export default {
  name: "CheckDiscountMember",

  props: {
    // 购物车产品列表
    productList: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  components: {
    MemberInfoBasic,
    FindMember
  },

  data() {
    return {
      levelInfo: {},
      isChecked: false,
      stopClick: false // 防止点击点击过快
    };
  },

  computed: {
    ...mapGetters("common", ["memberId"]),
    ...mapGetters("common", ["memberInfo"])
  },
  created() {
    if (this.memberId) {
      this.getInfo();
    }
  },
  methods: {
    getInfo() {
      let levelId = this.memberInfo.currentLevel;
      if (levelId) {
        memberLevel({ id: levelId })
          .then(res => {
            this.levelInfo = res;
          })
          .catch(() => {});
      }
    },
    handleClick() {
      this.isChecked = !this.isChecked;
    },
    reset() {
      this.isChecked = false;
    },
    // 校验
    async validate() {
      //如果无折扣，不让做任何点击操作
      if (this.levelInfo.counsumeDiscount === 1) {
        this.$message.warning("该会员等级，无任何折扣");
        this.stopClick = false;
        return false;
      } else if (this.isChecked) {
        // 校验购物车产品是否可用会员折扣
        await levelDiscount({
          memberId: this.memberId,
          productList: this.productList.map(item => {
            return {
              productId: item.productId,
              onePrice: item.sellingAmount,
              buyNum: item.productFlagPO.buyNum,
              ownerMerchantId: item.merchantSubScenicId
            };
          })
        })
          .then(res => {
            if (res) {
              return {
                merchantDiscountStr: "B"
              };
            } else {
              this.$message.warning("选购的产品不适用该会员等级");
              return false;
            }
          })
          .catch(() => {
            return false;
          });
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../assets/less/custom.less";

/*会员等级卡片*/
.level-card {
  overflow: hidden;
  position: relative;
  width: 250px;
  height: 136px;
  margin-right: 24px;
  border-radius: 14px;
  background-size: cover;
  color: #ffffff;
  .checked {
    width: 34px;
    height: 34px;
    background-color: #ffffff;
    position: absolute;
    right: 18px;
    bottom: 15px;
    border-radius: 50%;
    text-align: center;
    line-height: 34px;
    font-size: 22px;
    color: @primary;
  }
}
</style>
