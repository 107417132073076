<template>
  <footer class="footer">
    <div class="flex-between align-center">
      <div class="flex-grow">
        <!--支付信息-->
        <ul class="flex-start align-center">
          <li class="flex-shrink">
            订单金额：
            <b class="font-xl price">{{ cart.totalPrice | money }}</b>
          </li>
          <li class="ml-4x flex-shrink">
            游客数量：
            <b class="font-xl">{{ cart.travelerNum || 0 }}</b>
          </li>
          <li class="ml-4x">
            <span
              >产品数量：
              <b class="font-xl">{{ cart.productNum || 0 }}</b></span
            >
            <span class="gray" v-if="Object.keys(buyNumList).length"
              >（
              <span v-for="item in buyNumList" :key="item.productId"
                >{{ item.productName }} x{{ item.num }};
              </span>
              ）</span
            >
          </li>
          <li class="ml-4x" v-if="currentTopMenuKey === 'appPlayNew'">
            <time-end @pay-only="showPayOnly"></time-end>
          </li>
        </ul>

        <div class="flex-start align-center">
          <!--支付方式-->
          <pay-way
            class="mt-1x"
            v-permission="currentPermissions.paymentmethod"
            :cartId="cart.id"
            :preferential="
              cart.preferentialInfo &&
              cart.preferentialInfo.merchantDiscountAmount +
                cart.preferentialInfo.integralPayPrice >
                0
                ? true
                : false
            "
            :validate="validate"
            @success="success"
          ></pay-way>
          <!--打印方式-->
          <print-way
            class="flex-shrink mt-2x ml-2x"
            :cart-id="cart.id"
            v-if="currentTopMenuKey !== 'appRetailNew'"
          ></print-way>
          <div class="flex-shrink mt-2x ml-2x">
            <b>下单完成清除当前购物车</b>
            <a-switch
              class="ml-2x"
              :checked="getSettings.IsDeleteCart"
              @change="changeIsShowoLayout('IsDeleteCart')"
            />
          </div>
        </div>
      </div>

      <ul class="text-r align-end ml-2x">
        <!--<template v-if="cart.preferentialInfo">-->
        <!--暂时注释，不上优惠-2022-05-17-->
        <!--          <li class="pointer" @click="checkDiscount">
            <span class="dark">优惠：</span>
            <p class="ib" style="min-width: 100px;">
              <span class="price mr-x">{{
                cart.preferentialInfo.merchantDiscountAmount | money
              }}</span>
              <a-icon type="down" />
            </p>
          </li>
          <li class="mt-1x pointer" @click="checkDiscount">
            <span class="dark">积分抵扣：</span>
            <p class="ib" style="min-width: 100px;">
              <span class="price mr-x">{{
                cart.preferentialInfo.integralPayPrice | money
              }}</span>
            </p>
          </li>
        </template>
        <li v-else class="pointer" @click="checkDiscount">
          <span class="dark">优惠：</span>
          <p class="ib" style="min-width: 100px;">
            <span class="gray mr-x">请选择</span>
            <a-icon type="down" />
          </p>
        </li>-->
        <li>
          <span class="dark">结算金额：</span>
          <p class="ib price font-xxxl" style="min-width: 84px">
            {{ cart.settlementPrice | money }}
          </p>
        </li>
      </ul>
    </div>

    <!--功能菜单-->
    <div class="flex-between align-center mt-2x">
      <div class="flex-start flex-grow">
        <div
          class="f-btn relative"
          @click="showMemberClick"
          v-permission="currentPermissions.vip"
        >
          <a-button
            size="large"
            shape="round"
            :type="memberInfo.id ? 'primary' : ''"
            class="res"
          >
            <span
              v-if="memberInfo.id && memberInfo.whetherTemporaryMember === 1"
              >临时会员</span
            >
            <span v-else-if="memberInfo.id">{{ memberInfo.mobile }}</span>
            <span v-else>会员</span>
          </a-button>
          <i
            class="iconfont iconicon--shanchu red"
            v-if="memberInfo.id"
            @click.stop="clearMember"
          ></i>
        </div>
        <a-button
          v-permission="currentPermissions.memberRecharge"
          size="large"
          shape="round"
          class="f-btn"
          @click="memberRecharge"
          >会员充值</a-button
        >
        <a-button
          v-permission="currentPermissions.directpayment"
          size="large"
          shape="round"
          class="f-btn"
          @click="showPayOnly"
          >直接付款</a-button
        >
        <a-button
          v-permission="currentPermissions.hangingorder"
          size="large"
          shape="round"
          class="f-btn"
          :disabled="!cart.id"
          @click="saveCart"
          >挂起购物车</a-button
        >
        <a-button
          v-permission="currentPermissions.takeorder"
          shape="round"
          size="large"
          class="f-btn"
          @click="getCart"
          >取出购物车</a-button
        >
        <a-button
          v-if="currentTopMenuKey !== 'appRetailNew'"
          v-permission="currentPermissions.takeP"
          size="large"
          shape="round"
          class="f-btn"
          @click="changeTakeTicket"
          >取票</a-button
        >
        <a-button
          size="large"
          shape="round"
          class="f-btn"
          @click="changeShowCloseoutAccount"
          >清账</a-button
        >
      </div>
      <div>
        <!-- <a-button v-if="getSettings.IsShowCart" size="large" type="primary" shape="round" ghost>
          <a-icon type="shopping-cart" class="font-xxl" />
          <span>购物车</span>
        </a-button> -->
        <a-button
          v-if="!getSettings.IsShowSpeedOrder"
          size="large"
          type="primary"
          shape="round"
          class="submit"
          @click="submit"
          >提交订单</a-button
        >
      </div>
    </div>

    <!--取单-->
    <take-cart
      :card-id="cart.id"
      :visible.sync="showCartList"
      ref="ProductCalcFooterCart"
      @change="changeCart"
    ></take-cart>
    <!-- 取票 -->
    <take-ticket :visible.sync="showTakeTickett"></take-ticket>
    <!-- 清账 -->
    <closeout-account
      ref="CloseoutAccount"
      :visible.sync="showCloseoutAccount"
    ></closeout-account>
    <!--选择优惠-->
    <check-discount
      v-if="showDiscount"
      :cart-id="cart.id"
      :product-list="cart.productList"
      :preferential-info="cart.preferentialInfo"
      @change="changeCart"
      @close="showDiscount = false"
      :permissionbuttons="currentPermissions.permissionButtonObj"
    ></check-discount>
  </footer>
</template>

<script>
import { mapGetters } from "vuex";
import TakeCart from "./commonents/TakeCart";
import TimeEnd from "./commonents/TimeEnd";
import TakeTicket from "./commonents/TakeTicket.vue";
import mixins from "./mixins";
import { commonMember, commonPauseCart } from "../../api/cart";
import PayWay from "./commonents/PayWay";
import PrintWay from "./commonents/PrintWay";
import CheckDiscount from "./commonents/CheckDiscount";
import CloseoutAccount from "./commonents/CloseoutAccount";

export default {
  name: "ProductCalcFooter",
  components: {
    TakeCart,
    PayWay,
    PrintWay,
    CheckDiscount,
    TakeTicket,
    TimeEnd,
    CloseoutAccount
  },

  mixins: [mixins],
  data() {
    return {
      // 优惠类型
      discountType: {
        A: "整单折扣",
        B: "会员折扣",
        C: "优惠券",
        D: "营销活动"
      },
      // 购物车对象
      cart: {
        id: 0,
        totalPrice: 0, // 总价
        settlementPrice: 0, // 结算金额
        discountAmount: 0, // 优惠金额
        productNum: 0,
        travelerNum: 0,
        preferentialInfo: null, // 优惠对象
        totalProductList: [] // 产品列表
      },
      buyNumList: {}, // 产品数量明细
      orderId: "", // 订单id
      ownerFormatTypes: [], // 产品类型集合
      showCartList: false, // 取单弹窗
      showTakeTickett: false, // 取票弹窗
      showDiscount: false, // 选择优惠弹窗
      showCloseoutAccount: false, // 清账弹窗

      permissions: {
        // 票务新版 按钮权限
        appNewHome: {
          vip: "appNewHomeSellMember",
          directpayment: "appNewHomeSellCustomMoney",
          hangingorder: "appNewHomeSellEntryOrder",
          takeorder: "appNewHomeSellTakeTicket",
          paymentmethod: "appNewHomeSellMemberPaymentmethod",
          memberRecharge: "appNewHomeMemberRecharge",
          takeP: "appNewHometakeP",
          permissionButtonObj: {
            wholeorderdiscount: "appNewHomeWholeorderdiscount",
            memberdiscount: "appNewHomeMemberdiscount",
            coupon: "appNewHomecoupon",
            marketingactivities: "appNewHomeMarketingactivities",
            integraldeduction: "appNewHomeIntegraldeduction"
          }
        },
        // 组合产品新版 按钮权限
        appCombinationNew: {
          vip: "appCombinationNewSellProductVip",
          directpayment: "appCombinationNewSellProductDirectpayment",
          hangingorder: "appCombinationNewSellProductHangingorder",
          takeorder: "appCombinationNewSellProductTakeorder",
          paymentmethod: "appCombinationNewSellProductPaymentmethod",
          memberRecharge: "appCombinationNewMemberRecharge",
          takeP: "appCombinationNewtakeP",
          permissionButtonObj: {
            wholeorderdiscount:
              "appCombinationNewSellProductWholeorderdiscount",
            memberdiscount: "appCombinationNewSellProductMemberdiscount",
            coupon: "appCombinationNewSellProductcoupon",
            marketingactivities:
              "appCombinationNewSellProductmarketingactivities",
            integraldeduction: "appCombinationNewSellProductintegraldeduction"
          }
        },
        // 游乐新版 按钮权限控制
        appPlayNew: {
          vip: "appPlayNewSellMember",
          directpayment: "appPlayNewSellCustomMoney",
          hangingorder: "appPlayNewSellEntryOrder",
          takeorder: "appPlayNewSellTakeTicket",
          paymentmethod: "appPlayNewSellMemberPaymentmethod",
          memberRecharge: "appPlayNewMemberRecharge",
          takeP: "appPlayNewtakeP",
          permissionButtonObj: {
            wholeorderdiscount: "appPlayNewWholeorderdiscount",
            memberdiscount: "appPlayNewMemberdiscount",
            coupon: "appPlayNewcoupon",
            marketingactivities: "appPlayNewMarketingactivities",
            integraldeduction: "appPlayNewIntegraldeduction"
          }
        },
        // 零售新版 按钮权限控制
        appRetailNew: {
          vip: "appRetailNewSellMember",
          directpayment: "appRetailNewSellCustomMoney",
          hangingorder: "appRetailNewSellEntryOrder",
          takeorder: "appRetailNewSellTakeTicket",
          paymentmethod: "appRetailNewSellMemberPaymentmethod",
          memberRecharge: "appRetailNewMemberRecharge",
          takeP: "appRetailNewtakeP",
          permissionButtonObj: {
            wholeorderdiscount: "appRetailNewWholeorderdiscount",
            memberdiscount: "appRetailNewMemberdiscount",
            coupon: "appRetailNewcoupon",
            marketingactivities: "appRetailNewMarketingactivities",
            integraldeduction: "appRetailNewIntegraldeduction"
          }
        }
      }
    };
  },

  computed: {
    ...mapGetters({
      // 页面布局
      getSettings: "setting/getSettings"
    }),
    ...mapGetters("common", ["memberInfo"]),
    // 头部激活的currentTopMenuKey
    currentTopMenuKey() {
      return this.$store.state.common.currentTopMenuKey;
    },
    // 当前权限
    currentPermissions() {
      return this.permissions[this.currentTopMenuKey] || {}; // ||： 防止切换业态瞬间报错
    }
  },

  watch: {
    "memberInfo.id"(val) {
      // 如果切换了会员，则需要通知购物车
      if (this.cart.id) {
        this.updateMember(val);
      }
    }
  },

  methods: {
    // 下单后清除购物车-设置
    changeIsShowoLayout(name) {
      let item = this.getSettings[name];
      localStorage.setItem(`${this.currentTopMenuKey + name}`, !item);
      this.$store.commit(`setting/set${this.currentTopMenuKey + name}`, !item);
    },
    // 显示取票弹窗
    changeTakeTicket() {
      this.showTakeTickett = true;
    },
    // 更新购物车
    updateCart(res) {
      this.cart = res;
      const obj = {};
      // 产品数量明细
      res.productList?.forEach(item => {
        if (obj[item.productId]) {
          obj[item.productId].num++;
        } else {
          obj[item.productId] = {
            productId: item.productId,
            productName: item.productName,
            num: item.productFlagPO.buyNum
          };
        }
      });
      this.buyNumList = obj;
    },
    // 修改会员
    updateMember(val) {
      const obj = {
        cartId: this.cart.id
      };
      if (val) {
        obj.purchaserMemberId = val;
      }
      commonMember(obj).then(res => {
        this.changeCart(res);
      });
    },
    // 展示会员
    showMemberClick() {
      this.$emit("member", true);
    },
    // 会员充值
    memberRecharge() {
      this.$router.push({ path: "/home/member/MemberRecharge" });
    },
    // 展示直接付款
    showPayOnly(val) {
      this.$emit("pay-only", val);
    },
    // 提交订单
    submit() {
      this.$message.warning("该功能待开发");
    },
    // 支付成功
    success() {
      this.$emit("success", true);
    },

    // 挂单
    saveCart() {
      //  16 :"会员" 6 :"票务" 7 :"游乐" 8 :"剧院" 9 :"酒店" 10 :"餐饮" 11 :"零售" 12 :"租赁" 13 :"组合"
      const val = {
        appHome: 6,
        appNewHome: 6, // 票务
        appPlayNew: 7, // 游乐
        appCombinationNew: 13, // 组合产品
        appRetailNew: 11 // 零售
      };
      commonPauseCart({
        pauseCartFormatType: val[this.currentTopMenuKey],
        cartId: this.cart.id
      }).then(() => {
        this.$message.success("挂单成功");
        this.$emit("clear", true);
      });
    },
    // 取单
    getCart() {
      this.$refs.ProductCalcFooterCart.getList();
    },
    changeCart(res) {
      this.$emit("change-cart", res);
    },
    clearMember() {
      //清除会员信息
      this.$store.commit("common/UpdateMember", {});
    },
    // 选择优惠弹窗
    checkDiscount() {
      if (!this.cart.id) {
        this.$message.warning("请选购产品");
      } else {
        this.showDiscount = true;
      }
    },
    // 校验父组件的validate
    validate() {
      return this.$parent.validate();
    },
    // 清账弹窗
    changeShowCloseoutAccount() {
      this.$refs.CloseoutAccount.searchKey = ""; // 清空搜索值
      this.$refs.CloseoutAccount.onSearch();
      this.$nextTick(() => {
        this.showCloseoutAccount = true;
      });
    }
  }
};
</script>

<style scoped lang="less">
@import "../../assets/less/custom.less";
/*底部*/
.footer {
  z-index: 1;
  padding: 16px;
  box-shadow: 3px 0 6px rgba(0, 0, 0, 0.16);
  background: #fff;
  .iconfont {
    font-size: 22px;
    position: absolute;
    top: -15px;
    right: -13px;
    cursor: pointer;
  }
  .f-btn {
    /*font-size: 14px !important;*/
    min-width: 155px;
    margin-right: 16px;
  }
  .submit {
    width: 250px;
    margin-left: 16px;
  }

  /*确认订单弹窗*/
  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 500;
    background: rgba(12, 12, 12, 0.42);
    .center {
      width: 480px;
      padding: 24px;
      border-radius: 17px;
      background: #fff;
      z-index: 3;
      .modal-radio {
        display: block;
        height: 40px;
        margin-bottom: 12px;
        line-height: 40px;
      }
    }
  }
}
</style>
