<!--
  @name: 积分抵扣模块
-->
<template>
  <div class="flex-center flex-col align-center jfdk">
    <div class="member-info relative">
      <div class="flex-between font-l">
        <p>
          <span class="member-name">{{ memberInfo.name }}</span>
          <span class="gray ml-2x">{{ memberInfo.currentLevelName }}</span>
        </p>
        <span class="gray" v-if="memberInfo.mobile">
          手机号：{{
            memberInfo.mobile.substr(0, 3) +
              "****" +
              memberInfo.mobile.substr(7, 11)
          }}
        </span>
      </div>
      <p class="mid-text">会员剩余积分：</p>
      <div class="flex-between">
        <span class="font-xxxxl bold">{{ memberInfo.usableIntegral }}</span>
        <div class="change-btn pointer" @click="changeMember">更换会员</div>
      </div>
      <img
        class="member-img"
        :src="
          memberInfo.photo ||
            'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
        "
      />
    </div>
    <div class="flex-between align-center bold font-xl mt-4x mb-2x" v-if="rule">
      <span class="mr-4x">本次使用</span>
      <div class="flex-start step align-center mr-2x">
        <a-button
          class="point-btn"
          type="primary"
          icon="minus"
          @click="reduceNum"
        />
        <div class="quantity">{{ point }}</div>
        <!-- <a-input class="quantity" v-model="point"></a-input> -->
        <a-button
          class="point-btn"
          type="primary"
          icon="plus"
          @click="addNum"
        />
      </div>
      <div class="ml-4x">
        <span>积分抵扣</span>
        <span class="ml-x red">
          {{ (rule.integralDeductionMoney * count) | money("") }}
          元
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { integralRule } from "../../../api/member";
export default {
  name: "CheckDiscountIntegral",

  props: {
    // 优惠对象
    preferentialInfo: {
      type: Object,
      default() {
        return null;
      }
    }
  },

  data() {
    return {
      rule: null,
      point: 0, // 使用积分数量
      count: 0 // 积分抵扣倍数
    };
  },

  computed: {
    ...mapGetters("common", ["memberInfo"])
  },

  created() {
    //获取积分抵扣规则
    this.getIntegralRule();
  },

  methods: {
    ...mapActions("common", ["updateMember"]),
    // 更换会员
    changeMember() {
      this.updateMember({ id: "" });
    },
    // 获取积分规则
    getIntegralRule() {
      integralRule()
        .then(res => {
          this.rule = res;
          if (this.preferentialInfo && this.preferentialInfo.integralPayNum) {
            this.point = this.preferentialInfo.integralPayNum;
            this.count =
              this.preferentialInfo.integralPayNum /
              res.integralDeductionAmount;
          }
        })
        .catch(() => {});
    },
    // 减
    reduceNum() {
      if (this.count === 0) {
        return false;
      }
      this.count -= 1;
      this.point -= this.rule.integralDeductionAmount;

      this.$emit("change", this.point);
    },
    // 加
    addNum() {
      //每次加减的单位积分
      let unitAmount = this.rule.integralDeductionAmount;
      this.count += 1;
      this.point += unitAmount;

      //如果大于配置的积分抵扣上限
      if (
        this.point > this.rule.integralDeductionLimit &&
        this.rule.integralDeductionLimit !== 0
      ) {
        this.$message.warning(
          "最多使用积分为：" + this.rule.integralDeductionLimit
        );
        this.count -= 1;
        this.point -= unitAmount;
        return false;
      }
      //如果使用积分大于会员可用/剩余积分
      if (this.point > this.memberInfo.usableIntegral) {
        this.$message.warning("剩余积分不足");
        this.count -= 1;
        this.point -= unitAmount;
        return false;
      }
      this.$emit("change", this.point);
    }
  }
};
</script>

<style lang="less" scoped>
.jfdk {
  margin-top: 40px;
  .member-info {
    width: 604px;
    height: 200px;
    box-shadow: 0px 8px 22px 0px rgba(213, 213, 213, 0.09);
    border-radius: 14px;
    padding: 14px 26px 0;
    background: url("https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png")
      no-repeat center center;
    background-size: cover;
    .member-name {
      font-size: 20px;
    }
    .mid-text {
      margin-top: 80px;
    }
    .member-img {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      position: absolute;
      right: 33px;
      top: 41px;
    }
    .change-btn {
      width: 110px;
      height: 40px;
      border: 1px solid #535353;
      border-radius: 20px;
      color: #666;
      font-size: 16px;
      text-align: center;
      line-height: 40px;
    }
  }
}

.step {
  .point-btn {
    width: 70px;
    height: 60px;
  }
  .quantity {
    width: 160px;
    height: 60px;
    line-height: 60px;
    font-size: 28px;
    text-align: center;
    background: #f1f1f1;
    color: #111111;
    border-radius: 0;
    border: none;
  }
}
</style>
