<!--
  @name: 订单折扣 => 优惠券
-->
<template>
  <div class="relative">
    <a-button
      class="top-btn"
      icon="swap"
      @click="resetCheckcoupon('m')"
      v-show="!showMemberTab"
    >
      使用会员优惠券
    </a-button>
    <a-button
      class="top-btn"
      icon="swap"
      @click="resetCheckcoupon('d')"
      v-show="showMemberTab"
    >
      兑换优惠券
    </a-button>
    <!--兑换优惠券键盘-->
    <keyboard-pro
      class="keyword"
      v-model="code"
      @ok="checkCode"
      v-show="!showMemberTab && !tableData.length"
    >
      <template v-slot:input="{ confirm }">
        <a-form-model autocomplete="off">
          <a-input-search
            v-inputfocus
            ref="fouceRef"
            size="large"
            placeholder="请扫码查询/输入优惠券编号兑换"
            v-model="code"
            allow-clear
            @pressEnter="confirm()"
          >
            <a-icon slot="prefix" type="search" class="primary" />
            <a-button slot="enterButton">
              <a-icon type="scan" class="primary" />
            </a-button>
          </a-input-search>
        </a-form-model>
      </template>
    </keyboard-pro>
    <!-- 使用会员优惠券键盘 -->
    <find-member
      v-show="showMemberTab && !tableData.length"
      @success="getActivityList"
    ></find-member>

    <!-- 查询到的优惠券数据 -->
    <div
      v-show="tableData && tableData.length"
      class="flex-start flex-wrap counp-wrap"
    >
      <div
        class="yhq-wrap flex-start align-center pointer"
        v-for="(item, index) in tableData"
        :key="index"
        :class="{
          disable: !item.isCouponsAvailable,
          act: curIndex === index
        }"
        @click="selectCoupon(item, index)"
      >
        <div class="left pl-2x">
          <p class="text-1 font-xl">{{ item.name }}</p>
          <p v-if="item.startDate" class="font-s gray mt-x">
            有效期：{{ item.startDate | date }} 至
            {{ item.endDate | date }}
          </p>
          <p v-if="item.remark" class="font-s text-1 gray mt-x">
            {{ item.remark }}
          </p>
        </div>
        <div
          class="right ml-2x font-xxl relative"
          :class="{
            disable: !item.isCouponsAvailable,
            act: curIndex === index
          }"
        >
          <span class="font-l uni-text">¥</span>
          <span class="ml-x mon-text">{{
            item.discountedPriceText | money("")
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FindMember from "../../member/FindMember.vue";
import { mapActions, mapGetters } from "vuex";
import {
  windowCouponActivityList,
  windowRedeemCoupons
} from "../../../api/marketing";
import KeyboardPro from "../../common/KeyboardPro.vue";

export default {
  name: "CheckDiscountCoupon",
  props: {
    productList: {
      type: Array,
      default: function() {
        return [];
      }
    },
    // 优惠对象
    preferentialInfo: {
      type: Object,
      default() {
        return null;
      }
    }
  },

  components: {
    FindMember,
    KeyboardPro
  },

  computed: {
    ...mapGetters("common", ["memberId"])
  },

  data() {
    return {
      tableData: [],
      showMemberTab: false,
      code: "", //优惠券码
      curIndex: "" // 当前选中的优惠券
    };
  },
  created() {
    if (this.memberId) {
      this.showMemberTab = true;
      this.getActivityList();
    }
  },
  methods: {
    ...mapActions("order", ["removeOrderDiscountTextList"]),
    //获取可用优惠券
    getActivityList() {
      let list = this.productList.map(item => {
        return {
          productId: item.productId,
          salePrice: item.sellingAmount,
          count: item.productFlagPO.buyNum,
          merchantId: item.merchantSubScenicId
        };
      });
      windowCouponActivityList({
        memberId: this.memberId,
        orderChannelId: 6, //6是窗口
        productItems: list
      })
        .then(res => {
          if (!res) {
            this.$message.warning("该会员没有可用优惠券");
            return;
          }
          this.tableData = res;
          // 回显选中优惠券
          if (
            this.preferentialInfo &&
            this.preferentialInfo.merchantDiscountStr === "C"
          ) {
            let index = res.findIndex(
              item => item.couponCode === this.preferentialInfo.couponNo
            );
            if (index !== -1) this.curIndex = index;
          }
        })
        .catch(() => {});
    },
    // 选择优惠券
    selectCoupon(row, index) {
      if (!row.isCouponsAvailable) {
        this.$message.warning("该优惠券不满足使用条件");
        return;
      }
      // 点击当前选中的，取消
      if (index === this.curIndex) {
        this.curIndex = "";
      } else {
        this.curIndex = index;
      }
    },
    // 通过优惠券码，查询优惠券
    checkCode() {
      if (!this.code) {
        this.$message.warning("请先输入券码");
        return;
      }
      let list = this.productList.map(item => {
        return {
          productId: item.productId,
          salePrice: item.sellingAmount,
          count: item.productFlagPO.buyNum,
          merchantId: item.merchantSubScenicId
        };
      });
      windowRedeemCoupons({
        couponCode: this.code,
        productItems: list
      })
        .then(res => {
          if (res) {
            this.tableData = res;
          }
        })
        .catch(() => {});
    },
    // 兑换优惠券 切换
    resetCheckcoupon(type) {
      this.tableData = [];
      this.curIndex = "";
      this.showMemberTab = type === "m";
      if (this.showMemberTab && this.memberId) {
        this.getActivityList();
      }
    },
    // 校验返回数据
    validate() {
      if (this.curIndex === "") {
        return {}; // 不需要更新优惠券信息
      } else {
        const item = this.tableData[this.curIndex];
        return {
          merchantDiscountStr: "C",
          marketingId: item.id, // 优惠券id
          couponNo: item.couponCode // 优惠券编号
        };
      }
    }
  }
};
</script>

<style lang="less" scoped>
/*键盘  */
.keyword {
  margin: 16px auto;
}

.top-btn {
  width: 190px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border: 1px solid #f8724a;
  border-radius: 25px;
  position: absolute;
  right: 10px;
  top: -60px;
  color: #f8724a;
}

.counp-wrap {
  max-height: 340px;
  overflow-y: auto;
  overflow-x: hidden;
}

.yhq-wrap {
  width: 350px;
  height: 96px;
  margin: 0 23px 25px 0;
  background: url("../../../assets/images/yhq.png") no-repeat center center;
  background-size: cover;
  &.act {
    background: url("../../../assets/images/yhq_act.png") no-repeat center
      center;
    color: #fff;
    .gray {
      color: #fff;
    }
  }
  &.disable {
    background: url("../../../assets/images/yhq_disable.png") no-repeat center
      center;
    color: #999;
  }
  .left {
    width: 230px;
  }
  .right {
    color: #f87a4b;
    &.disable {
      color: #999;
    }
    &.act {
      color: #fff;
    }
    .uni-text {
      position: absolute;
      left: 0;
      top: 6px;
    }
    .mon-text {
      margin-left: 10px;
    }
  }
}
</style>
