<!-- 
整单优惠
-->
<template>
  <div class="flex-between">
    <div class="left flex-shrink" style="width:48%">
      <div class="flex-start title-min pv-2x pl-x">
        <div class="line"></div>
        <p>整单折扣</p>
      </div>
      <ul class="flex-start flex-wrap align-center pl-2x ul-wrap">
        <!-- o折扣item -->
        <li
          v-for="item in discountData"
          :key="item.id"
          :class="{ active: item.id == checkedId }"
          @click="setSelectId(item.id, item.settingType)"
          class="card flex-around"
        >
          {{ item.settingValue / 10 }}折
        </li>
        <!-- o自定义折扣按钮 -->
        <li
          class="card flex-center"
          :class="{ active: checkedId === 'customDiscount' }"
          @click="customDiscount"
        >
          <div>
            <p>自定义折扣</p>
            <p v-show="discount">{{ discount }}折</p>
          </div>
        </li>
      </ul>
      <div class="title-min flex-start pv-2x pl-x">
        <div class="line"></div>
        <p>整单减价</p>
      </div>
      <ul class="flex-start pl-2x align-center flex-wrap ul-wrap">
        <!-- o减价item -->
        <li
          v-for="item in reduceData"
          :key="item.id"
          class="card flex-center"
          :class="{ active: item.id == checkedId }"
          @click="setSelectId(item.id)"
        >
          减 {{ item.settingValue / 100 }}元
        </li>
        <!-- o自定义减价按钮 -->
        <li
          class="card flex-center"
          :class="{ active: checkedId === 'customReduce' }"
          @click="customReduce"
        >
          <div>
            <p>自定义减价</p>
            <p v-show="reduce">减{{ reduce }}元</p>
          </div>
        </li>
      </ul>
    </div>
    <!-- 右侧键盘 -->
    <div class="right flex-shrink">
      <!-- 自定义折扣键盘 -->
      <keyboard-pro v-show="visible === 1" type="2" v-model="discount">
        <template v-slot:input="{ confirm }">
          <a-form-model autocomplete="off">
            <a-input
              v-inputfocus
              :max-length="3"
              v-model="discount"
              @pressEnter="confirm()"
              allow-clear
              class="prefix-input"
              size="large"
              placeholder="请输入折扣"
            >
              <span slot="prefix" class="primary">整单折扣</span>
              <span slot="suffix" class="primary">折</span>
            </a-input>
          </a-form-model>
        </template>
      </keyboard-pro>
      <!-- 自定义整单减价键盘 -->
      <keyboard-pro v-show="visible === 2" type="2" v-model="reduce">
        <template v-slot:input="{ confirm }">
          <a-form-model autocomplete="off">
            <a-input
              v-inputfocus
              :max-length="8"
              v-model="reduce"
              @pressEnter="confirm()"
              allow-clear
              class="prefix-input"
              size="large"
              placeholder="请输入金额"
            >
              <span slot="prefix" class="primary">整单减价</span>
              <span slot="suffix" class="primary">元</span>
            </a-input>
          </a-form-model>
        </template>
      </keyboard-pro>
    </div>
  </div>
</template>

<script>
import { windowDiscountFindList } from "../../../api/marketing";
import KeyboardPro from "../../common/KeyboardPro.vue";
import { fix2Reg } from "../../../utils/global";

export default {
  name: "CheckDiscountOrder",
  components: {
    KeyboardPro
  },
  props: {
    // 优惠对象
    preferentialInfo: {
      type: Object,
      default() {
        return null;
      }
    }
  },
  data() {
    return {
      checkedId: "", //选中的折扣id
      discountData: [], //折扣数据
      reduceData: [], //整单减价数据
      discount: "", //自定义折扣
      reduce: "", //自定义减价
      visible: "" // 1显示折扣键盘，2显示减价键盘
    };
  },
  computed: {},
  created() {
    this.getList();
  },
  methods: {
    getList() {
      windowDiscountFindList()
        .then(res => {
          if (res) {
            this.discountData = res.filter(item => {
              return item.settingType === 2;
            });
            this.reduceData = res.filter(item => {
              return item.settingType === 1;
            });

            // 回显数据
            if (
              this.preferentialInfo &&
              this.preferentialInfo.merchantDiscountStr === "A"
            ) {
              const {
                discountId,
                customAmount,
                settingType
              } = this.preferentialInfo;
              this.checkedId = discountId || "";
              // 没有折扣id则为自定义
              if (!discountId) {
                // 自定义立减
                if (settingType === 1) {
                  this.reduce = customAmount / 100;
                  this.checkedId = "customReduce";
                }
                // 自定义折扣
                else {
                  this.checkedId = "customDiscount";
                  this.discount = customAmount;
                }
              }
            }
          }
        })
        .catch(() => {});
    },
    //记录选择的折扣id
    setSelectId(id) {
      this.discount = ""; //清空自定义折扣
      this.reduce = ""; //清空自定义减价
      if (id === this.checkedId) {
        this.checkedId = "";
      } else {
        this.reset();
        this.checkedId = id;
      }
    },
    // 自定义折扣
    customDiscount() {
      if (this.checkedId === "customDiscount") {
        this.reset();
      } else {
        this.checkedId = "customDiscount";
        this.visible = 1;
        this.reduce = "";
      }
    },
    customReduce() {
      if (this.checkedId === "customReduce") {
        this.reset();
      } else {
        this.checkedId = "customReduce";
        this.visible = 2;
        this.discount = "";
      }
    },
    //重置数据
    reset() {
      this.checkedId = "";
      this.discount = ""; //自定义折扣
      this.reduce = ""; //自定义减价
      this.visible = "";
    },
    // 校验表单,返回结果
    validate() {
      let ok = false;
      const obj = {
        // （整单折扣  A）（会员折扣 B）（优惠券 C）（营销活动  D）
        merchantDiscountStr: "A"
      };
      // 自定义折扣
      if (this.checkedId === "customDiscount") {
        let reg = /^[1-9]{1}$|^[0-9]{1}\.[0-9]{1}$|^0$/;
        if (!reg.test(this.discount)) {
          this.$message.error(
            "自定义折扣校验不通过，请输入数字，最多保留1位小数"
          );
        } else {
          ok = true;
        }
      }
      // 自定义减价
      else if (this.checkedId === "customReduce") {
        if (!fix2Reg.test(this.reduce)) {
          this.$message.error(
            "自定义减价校验不通过，请输入数字，最多保留2位小数"
          );
        } else {
          ok = true;
        }
      }
      // 如果选择的是非自定义折扣则有折扣id
      else if (this.checkedId) {
        obj.discountId = this.checkedId;
        return obj;
      }
      if (ok) {
        //立减的数值
        if (this.checkedId === "customReduce") {
          obj.settingType = 1;
          obj.customAmount = Math.round(this.reduce * 100);
        }
        //折扣的数值
        else {
          obj.settingType = 2;
          obj.customAmount = this.discount;
        }
        return obj;
      }
      // 校验不通过不更新优惠
      else {
        return false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.title-min {
  align-items: center;
  font-size: 16px;
  .line {
    margin-right: 12px;
    width: 6px;
    height: 28px;
    background: #4182fc;
    border-radius: 3px;
  }
}
.ul-wrap {
  max-height: 116px;
  overflow-y: auto;
  overflow-x: hidden;
}
/*卡片*/
.card {
  flex-shrink: 0;
  align-items: center;
  width: 140px;
  height: 56px;
  margin: 0 19px 19px 0;
  border: 1px solid #c0c0c0;
  border-radius: 6px;
  text-align: center;
  font-size: 16px;
  color: #666;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 9px 0 rgba(116, 116, 116, 0.22);
  }
  &.active {
    border: none;
    box-shadow: 3px 0 9px 0 rgba(116, 116, 116, 0.26);
    border-radius: 10px;
    background: linear-gradient(181deg, #44b8fb, #4d8afb);
    color: #fff;
  }
}
/*键盘  */
.keyword {
  width: 540px;
  margin: 32px auto;
}
</style>
