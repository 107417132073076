<template>
  <div>
    <!-- 清账弹窗组件 -->
    <a-modal
      width="600px"
      centered
      :z-index="200"
      :visible="visible"
      :closable="false"
      :body-style="{
        padding: '0px',
        maxHeight: '500px',
        overflowY: 'auto'
      }"
    >
      <!-- 搜索框 -->
      <a-input-search
        v-if="!orderId"
        v-inputfocus
        allow-clear
        class="search-input p-2x"
        placeholder="请输入会员手机号或挂账单号"
        v-model="searchKey"
        @search="onSearch"
      >
        <a-button slot="enterButton" type="primary">
          查询
        </a-button>
      </a-input-search>
      <div>
        <p v-if="!showOrderList" class="bold ph-2x pt-2x">
          订单产品列表:
        </p>
        <!-- 订单列表 -->
        <div class="list" v-if="showOrderList">
          <div
            class="flex-between align-center p-2x bd-t"
            v-for="(item, index) in orderList"
            :key="index"
          >
            <div>
              <p>总单号：{{ item.mainOrderId }}</p>
              <p>总单页态集：{{ item.ownerFormatTypeInfo }}</p>
              <p>下单时间：{{ item.createTime | date("YYYY-MM-DD hh:mm") }}</p>
            </div>
            <div>
              <a-button @click="getFindOncreditOrderInfo(item.mainOrderId)"
                >清账</a-button
              >
            </div>
          </div>
          <a-empty v-if="orderList.length === 0" class="mt-2x" />
        </div>
        <!-- 订单产品列表 -->
        <div class="list ph-2x" v-else>
          <div
            class="flex-start align-center mt-2x"
            v-for="item in productInfo"
            :key="item.id"
          >
            <div class="flex-between product-info mr-3x">
              <p>
                {{ item.productName }}
              </p>
              <div class="ml-3x">x{{ item.buyNum }}</div>
            </div>
            <div>
              <a-input
                addon-before="确认清账"
                placeholder="请输入"
                type="number"
                v-model="item.num"
                @blur="blurNum(item)"
              />
            </div>
          </div>
          <a-empty v-if="productInfo.length === 0" class="mt-2x" />
        </div>
      </div>
      <template #footer>
        <div class="flex-center">
          <a-button @click="close" class="close-btn">取消</a-button>
          <a-button @click="ok" class="ok-btn" :disabled="disabled"
            >清账</a-button
          >
        </div>
      </template>
    </a-modal>
    <pay-way ref="PayWay" isModal :show-pay-after="false"></pay-way>
  </div>
</template>

<script>
import {
  findOncreditOrderInfo,
  settleOncreditOrder,
  findOncreditOrderList
} from "../../../api/order";
import PayWay from "./PayWay.vue";
export default {
  name: "CloseoutAccount",
  components: { PayWay },
  props: {
    // 显示清账弹窗
    visible: {
      type: Boolean,
      default: false
    },
    // 订单号
    orderId: {
      type: [Number, String],
      default: ""
    }
  },
  data() {
    return {
      searchKey: "", //搜索值
      productInfo: [], // 产品列表
      orderList: [], // 订单列表
      showOrderList: true, // 显示订单列表
      disabled: true, // 清账按钮禁用
      mainOrderId: 0 //清账时用的订单id
    };
  },
  methods: {
    // 搜索
    onSearch() {
      // 传订单号过来就查订单详情
      if (this.orderId) {
        this.getFindOncreditOrderInfo(this.orderId || this.searchKey);
      } else {
        // 查列表
        findOncreditOrderList({ code: this.searchKey })
          .then(res => {
            // 只有一个时直接显示详情
            if (res.length === 1) {
              this.getFindOncreditOrderInfo(res[0].mainOrderId);
            } else {
              this.orderList = res;
              this.showOrderList = true;
              this.disabled = true;
            }
          })
          .catch(() => {});
      }
    },
    // 获取订单详情
    getFindOncreditOrderInfo(orderId) {
      this.mainOrderId = orderId;
      findOncreditOrderInfo({ orderId })
        .then(res => {
          if (res) {
            res.forEach(val => {
              val.num = val.buyNum;
            });
            this.productInfo = res;
          } else {
            this.productInfo = [];
          }
          this.showOrderList = false;
          this.disabled = false;
        })
        .catch(() => {});
    },
    // 输入数量
    blurNum(item) {
      if (item.num > item.buyNum) {
        this.$message.warning("不能大于购买产品数量");
        item.num = item.buyNum;
        return;
      }
      if (!item.num) {
        item.num = 0;
      }
    },
    // 确认
    ok() {
      let total = 0;
      let changeProducts = [];
      this.productInfo.forEach(item => {
        total += Number(item.num);
        let val = {
          num: item.num,
          id: item.id,
          productId: item.productId
        };
        changeProducts.push(val);
      });
      if (total < 1) {
        this.$message.warning("产品总数量必须大于0");
        return;
      }
      settleOncreditOrder({
        orderId: this.mainOrderId,
        changeProducts
      })
        .then(res => {
          if (res) {
            this.close();
            this.$refs.PayWay.orderId = res.orderId;
            this.$refs.PayWay.settlementPrice = res.settlementPrice;
            this.$refs.PayWay.refundPrice = res.refundPrice;
            this.$refs.PayWay.pay();
          }
        })
        .catch(() => {});
    },
    // 关闭
    close() {
      this.$emit("update:visible", false);
    }
  }
};
</script>

<style lang="less" scoped>
.search-input {
  width: 389px;
}
.list {
  overflow: auto;
  max-height: 500px;
  min-height: 200px;
  .product-info {
    color: #707070;
    width: 400px;
  }
}
.close-btn {
  width: 108px;
  height: 32px;
  background: #ffffff;
  border-radius: 16px;
  border: 1px solid #c7c7c7;
  color: #c7c7c7;
}
.ok-btn {
  width: 108px;
  height: 32px;
  background: #3566d9;
  border-radius: 16px;
  color: #fff;
}
</style>
