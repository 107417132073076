<!--
  查看所有优惠弹窗
-->
<template>
  <a-modal
    :visible="true"
    :footer="null"
    title=""
    width="1100px"
    @cancel="close"
    :body-style="bodyStyle"
  >
    <a-tabs :active-key="tabKey" type="card" @change="tabChange">
      <a-tab-pane key="1" tab="订单折扣">
        <div class="flex-start custome-tab-wrap">
          <div
            v-permission="permissionbuttons.wholeorderdiscount"
            :class="{ act: discountTab == 0 }"
            class="custome-tab pointer mr-3x"
            @click="discountChange(0)"
          >
            整单优惠
          </div>
          <div
            v-permission="permissionbuttons.memberdiscount"
            :class="{ act: discountTab == 1 }"
            class="custome-tab pointer mr-3x"
            @click="discountChange(1)"
          >
            会员折扣
          </div>
          <div
            v-permission="permissionbuttons.coupon"
            :class="{ act: discountTab == 2 }"
            class="custome-tab pointer mr-3x"
            @click="discountChange(2)"
          >
            优惠券
          </div>
          <div
            v-permission="permissionbuttons.marketingactivities"
            :class="{ act: discountTab == 3 }"
            class="custome-tab pointer mr-3x"
            @click="discountChange(3)"
          >
            营销活动
          </div>
        </div>
        <!-- 整单优惠 -->
        <check-discount-order
          ref="mytab0"
          v-if="discountTab == 0"
          :preferential-info="preferentialInfo"
        ></check-discount-order>
        <!-- 会员折扣 -->
        <check-discount-member
          ref="mytab1"
          v-if="discountTab == 1"
          :cart-id="cartId"
          :product-list="productList"
          :preferential-info="preferentialInfo"
        ></check-discount-member>
        <!-- 优惠券 -->
        <check-discount-coupon
          ref="mytab2"
          v-if="discountTab == 2"
          :cart-id="cartId"
          :product-list="productList"
          :preferential-info="preferentialInfo"
        ></check-discount-coupon>
        <!-- 营销活动 -->
        <check-discount-activity
          ref="mytab3"
          v-if="discountTab == 3"
          :product-list="productList"
          :preferential-info="preferentialInfo"
          @enter="sure"
        ></check-discount-activity>
      </a-tab-pane>

      <a-tab-pane
        v-permission="permissionbuttons.integraldeduction"
        key="2"
        tab="积分抵扣"
        v-if="pointOpen"
      >
        <check-discount-integral
          ref="integral"
          v-if="memberId"
          :preferential-info="preferentialInfo"
          @change="integralChange"
        ></check-discount-integral>
        <div v-else>
          <find-member></find-member>
        </div>
      </a-tab-pane>
    </a-tabs>
    <div class="flex-center mt-2x">
      <a-button
        @click="sure"
        type="primary"
        size="large"
        shape="round"
        class="btn-lg"
        :loading="loading"
      >
        确定
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import FindMember from "../../member/FindMember.vue"; //导入搜索会员的键盘
import CheckDiscountOrder from "./CheckDiscountOrder.vue"; //整单优惠
import CheckDiscountMember from "./CheckDiscountMember"; // 会员折扣
import CheckDiscountCoupon from "./CheckDiscountCoupon"; //优惠券
import CheckDiscountActivity from "./CheckDiscountActivity.vue"; //营销活动
import CheckDiscountIntegral from "./CheckDiscountIntegral.vue"; //积分抵扣
import { integralRule } from "../../../api/member";
import { commonDiscount } from "../../../api/cart";
import { mapGetters } from "vuex";

export default {
  name: "CheckDiscount",

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    cartId: {
      type: Number,
      default: 0
    },
    // 优惠对象
    preferentialInfo: {
      type: Object,
      default() {
        return null;
      }
    },
    // 购物车产品列表
    productList: {
      type: Array,
      default: function() {
        return [];
      }
    },
    permissionbuttons: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },

  components: {
    CheckDiscountOrder, //整单折扣
    CheckDiscountMember, // 会员折扣
    CheckDiscountCoupon, //优惠券
    CheckDiscountActivity, //营销活动
    CheckDiscountIntegral,
    FindMember
  },

  data() {
    return {
      tabKey: "1", // 订单折扣、积分抵扣tab key
      discountTab: 0, // 优惠选中项
      loading: false,
      pointOpen: false,
      integral: "", // 使用积分数量
      bodyStyle: {}, // 弹窗高度样式
      customeTabList: ["整单优惠", "会员折扣", "优惠券", "营销活动"]
    };
  },

  computed: {
    ...mapGetters("common", ["memberId"])
  },

  created() {
    // 激活选中的折扣
    if (this.preferentialInfo && this.preferentialInfo.merchantDiscountStr) {
      let arr = ["A", "B", "C", "D"];
      this.discountTab = arr.findIndex(
        item => item === this.preferentialInfo.merchantDiscountStr
      );
    }
    this.getPointRule();
    this.initBodyStyle();
  },

  methods: {
    // 配置弹窗的最大高度
    initBodyStyle() {
      // T2设备，设置弹窗最大高度
      if (document.documentElement.clientHeight < 1000) {
        this.bodyStyle = {
          "max-height": "600px",
          overflow: "auto"
        };
      } else {
        this.bodyStyle = {};
      }
    },
    //启用积分功能开关 并且 积分抵扣现金开关 时
    getPointRule() {
      integralRule().then(res => {
        if (res.integralSwitch && res.offsetCashSwitch) {
          this.pointOpen = true;
        }
      });
    },
    // 关闭弹窗
    close() {
      this.$emit("close", false);
    },
    // tab切换
    tabChange(val) {
      this.tabKey = val;
    },
    // 订单折扣切换
    discountChange(i) {
      this.discountTab = i;
    },

    // 积分抵扣
    integralChange(val) {
      this.integral = val;
    },

    // 确定弹窗
    async sure() {
      if (this.loading) return;
      this.loading = true;
      // 校验订单折扣
      const data = await this.$refs[`mytab${this.discountTab}`].validate();
      if (data || this.integral !== "") {
        const discountDTO = {
          ...data
        };
        if (this.integral !== "") {
          discountDTO.integralPayNum = this.integral; // 使用积分数量
        }
        this.loading = false;
        if (Object.keys(discountDTO).length > 0) {
          commonDiscount({
            cartId: this.cartId,
            discountDTO
          })
            .then(res => {
              this.$emit("change", res);
              this.close();
            })
            .catch(() => {})
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      } else {
        this.tabKey = "1";
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../assets/less/custom";

.custome-tab-wrap {
  margin-bottom: 30px;
}
.custome-tab {
  width: 100px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  &.act {
    background: @primary;
    border-radius: 19px;
    color: #fff;
  }
}

.btn-lg {
  width: 280px;
  height: 50px;
  background: linear-gradient(0deg, #45b5fb 0%, #4c8ffb 100%);
  border-radius: 25px;
}
</style>
