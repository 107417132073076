<!--今日销售-->
<template>
  <!--今日销售-->
  <section class="section">
    <div class="flex-start align-center">
      <p
        class="font-20 pointer title"
        :class="{ 'checked-title': type === 1 }"
        @click="clickType(1)"
      >
        今日销售
      </p>
      <p
        class="font-20 pointer title"
        :class="{ 'checked-title': type === 2 }"
        @click="clickType(2)"
      >
        今日核销
      </p>
      <div
        @click="showSellRecord('IsShowSellRecord')"
        class="flex-center align-center bd round ph-1x h-24 mb-1x pointer"
      >
        <a-icon
          :type="getSettings.IsShowSellRecord ? 'up' : 'down'"
          class="font-xl"
        />
      </div>
    </div>
    <template v-if="getSettings.IsShowSellRecord">
      <div class="flex-between align-center mt-2x">
        <div class="flex-start align-center">
          <a-input-search
            v-inputfocus
            style="width: 420px"
            placeholder="请输入订单编号/取票号/销售编号/游客姓名"
            @search="onSearch"
          >
            <a-button slot="enterButton" icon="search" type="primary" ghost>
              搜索
            </a-button>
          </a-input-search>
          <p class="ml-2x font-l">
            <span class="bold">今日共计：</span>
            <span class="mr-2x"
              >销售产品 <span class="red">{{ buyNumSum }}</span> 件</span
            >
            <span
              >接待游客 <span class="red">{{ statEnter }}</span> 人</span
            >
          </p>
        </div>
        <p class="ml-2x font-l" v-if="type === 1">
          <span class="bold mr-2x"
            >总实收：{{
              (actualAmountSum - totalRefundAmountSum) | money
            }}</span
          >
          <span class="bold mr-2x"
            >总销售额：{{ actualAmountSum | money }}</span
          >
          <span class="bold">总退款：{{ totalRefundAmountSum | money }}</span>
        </p>
        <!-- <a-button shape="round" ghoast @click="batchPrint">批量重打</a-button> -->
      </div>

      <div class="list-box" v-if="type === 1">
        <a-row class="th">
          <!-- <a-col :span="1" class="td">
            <a-checkbox @change="checkAll"></a-checkbox>
          </a-col>
          <a-col :span="1" class="td">序号</a-col> -->
          <a-col :span="3" class="td">订单编号</a-col>
          <a-col :span="3" class="td">所属商户</a-col>
          <a-col :span="3" class="td">下单时间</a-col>
          <a-col :span="3" class="td">支付金额</a-col>
          <a-col :span="4" class="td">支付方式</a-col>
          <a-col :span="3" class="td">支付状态</a-col>
          <a-col :span="5" class="td">操作</a-col>
        </a-row>

        <div v-for="(item, idx) in salesrecordList" :key="item.id">
          <a-row class="tr">
            <!-- <a-col :span="1" class="td">
              <a-checkbox
                :checked="item.checked"
                @change="checkSalesrecord(idx)"
              ></a-checkbox>
            </a-col>
            <a-col :span="1" class="td">{{ idx + 1 }}</a-col> -->
            <a-col :span="3" class="td">{{ item.id }}</a-col>
            <a-col :span="3" class="td">{{ item.ownerMerchants }}</a-col>
            <a-col :span="3" class="td">{{
              item.createTime | date("YYYY-MM-DD hh:mm")
            }}</a-col>
            <a-col :span="3" class="td">{{ item.actualAmount | money }}</a-col>
            <a-col :span="4" class="td">{{ item.payWayName }}</a-col>
            <a-col :span="3" class="td">{{ item.orderStatusText }}</a-col>
            <a-col :span="5" class="td">
              <p
                class="del flex-shrink P-1x"
                @click="batchticketprint(item.id)"
              >
                重打整单
              </p>
              <a-button
                type="link"
                :disabled="!chargeBackList.includes(item.orderStatus)"
                @click="chargeBack(item.id)"
                >退单
              </a-button>
              <div class="del flex-shrink P-1x" @click="showDetail(idx)">
                <span class="mr-x">明细</span>
                <a-icon :type="item.showDetail ? 'up' : 'down'" />
              </div>
            </a-col>
          </a-row>

          <div v-show="item.showDetail">
            <a-row class="th">
              <!-- <a-col :span="1" :offset="1" class="td">序号</a-col> -->
              <a-col :span="7" class="td">销售编号</a-col>
              <a-col :span="7" class="td">产品名称</a-col>
              <a-col :span="4" class="td">支付金额</a-col>
              <a-col :span="5" class="td">操作</a-col>
            </a-row>
            <a-row class="tr" v-for="val in item.details" :key="val.id">
              <!-- <a-col :span="1" :offset="1" class="td">{{ vkey + 1 }}</a-col> -->
              <a-col :span="7" class="td">{{ val.auxiliaryId }}</a-col>
              <a-col :span="7" class="td">{{ val.productName }}</a-col>
              <a-col :span="4" class="td">{{
                val.receivedAmount | money
              }}</a-col>
              <a-col :span="5" class="td">
                <p
                  class="del flex-shrink P-1x"
                  @click="ticketnoPrint(val.auxiliaryId)"
                  v-if="currentTopMenuKey !== 'appRetailNew'"
                >
                  重打
                </p>
                <a-button
                  type="link"
                  :disabled="!checkAuthorityBtn(val).includes('退款')"
                  @click="refundDetail(item, val)"
                  >退款
                </a-button>
              </a-col>
            </a-row>
          </div>
        </div>
      </div>
      <div class="list-box" v-if="type === 2">
        <a-row class="th">
          <a-col :span="currentTopMenuKey === 'appPlayNew' ? 6 : 8" class="td"
            >票号</a-col
          >
          <a-col :span="currentTopMenuKey === 'appPlayNew' ? 6 : 8" class="td"
            >产品名称</a-col
          >
          <a-col :span="currentTopMenuKey === 'appPlayNew' ? 6 : 8" class="td"
            >核销时间</a-col
          >
          <a-col :span="6" class="td" v-if="currentTopMenuKey === 'appPlayNew'"
            >结束时间</a-col
          >
        </a-row>
        <a-row class="tr" v-for="item in verificationList" :key="item.id">
          <a-col
            :span="currentTopMenuKey === 'appPlayNew' ? 6 : 8"
            class="td"
            >{{ item.mainAuxiliaryId }}</a-col
          >
          <a-col
            :span="currentTopMenuKey === 'appPlayNew' ? 6 : 8"
            class="td"
            >{{ item.productName }}</a-col
          >
          <a-col
            :span="currentTopMenuKey === 'appPlayNew' ? 6 : 8"
            class="td"
            >{{ item.writeOffTime | date("YYYY-MM-DD hh:mm") }}</a-col
          >
          <a-col
            :span="6"
            class="td"
            v-if="currentTopMenuKey === 'appPlayNew'"
            >{{
              item.backWriteOffTime || "--" | date("YYYY-MM-DD hh:mm")
            }}</a-col
          >
        </a-row>
      </div>
      <a-pagination
        v-model="page"
        :default-page-size="size"
        :total="total"
        :page-size-options="['10', '20', '50', '100']"
        show-size-changer
        @change="loadData"
        @showSizeChange="sizeChange"
        class="flex-end mt-2x"
      />
    </template>
    <!-- 退款弹窗 -->
    <refund-public
      :title="title"
      :refund-show="visible"
      :refund-info="refundInfo"
      @success="refundOk"
      @cancel="visible = false"
    ></refund-public>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import RefundPublic from "@/views/home/orderNew/components/RefundPublic.vue";
import {
  orderSalesrecord,
  orderBatchticketprint,
  orderTicketnoprint,
  getVerificationrecord,
  orderWhackretailprint
} from "../../api/search";
import {
  refundOrderCheck,
  refundProductCheck,
  refundOrder,
  refundProduct,
  orderDetailInfoNew
} from "@/api/order";
import HardwareService from "../../common/domains/hardware-domain/hardwareService";
import { checkAuthorityBtn } from "@/utils/global";
export default {
  name: "ProductSaleRecord",
  components: { RefundPublic },
  data() {
    return {
      checkAuthorityBtn,
      page: 1, // 页数
      size: 10, // 每页多少条数据
      total: 0, // 总条数
      salesrecordList: [], // 今日销售
      verificationList: [], // 今日核销
      keyword: "", // 筛选
      // 退单退款
      visible: false,
      refundInfo: {}, // 整单退款校验信息
      orderId: "", // 退款订单编号
      detailItem: {}, // 当前点击退款的产品
      // productName: "", // 退款产品名称
      // productCategoryId: "", // 产品业务类型id
      refundType: 1, // 退票类型
      buyNumSum: 0, // 产品数量
      statEnter: 0, // 接待游客多少人
      actualAmountSum: 0, // 总销售
      totalRefundAmountSum: 0, // 总退款
      title: "", // 退款title
      type: 1, // 1 今日销售 2今日核销
      chargeBackList: [3, 9, 16, 18] // 退单按钮可选中状态值
    };
  },
  computed: {
    ...mapGetters({
      // 页面布局
      getSettings: "setting/getSettings"
    }),
    // 头部激活的currentTopMenuKey
    currentTopMenuKey() {
      return this.$store.state.common.currentTopMenuKey;
    }
  },

  created() {
    this.loadData();
  },
  methods: {
    // 选择类型
    clickType(type) {
      this.page = 1;
      this.type = type;
      this.loadData();
    },
    // 加载数据
    loadData() {
      if (this.type === 1) {
        this.loadOrderSalesrecord();
      } else {
        this.loadGetVerificationrecord();
      }
    },
    onSearch(keyword) {
      this.keyword = keyword;
      this.page = 1;
      this.loadData();
    },
    // 核销记录
    loadGetVerificationrecord() {
      let formats = 1; //业态: 1票务 2组合产品 3零售 4游乐
      if (this.currentTopMenuKey === "appNewHome") {
        formats = 1;
      } else if (this.currentTopMenuKey === "appCombinationNew") {
        formats = 2;
      } else if (this.currentTopMenuKey === "appRetailNew") {
        formats = 3;
      } else if (this.currentTopMenuKey === "appPlayNew") {
        formats = 4;
      }
      getVerificationrecord({
        keyword: this.keyword,
        page: this.page,
        size: this.size,
        formats
      })
        .then(({ records, total }) => {
          this.total = total;
          this.verificationList = records;
        })
        .catch();
    },
    // 加载销售数据
    loadOrderSalesrecord() {
      let formats = 1;
      if (this.currentTopMenuKey === "appNewHome") {
        formats = 1;
      } else if (this.currentTopMenuKey === "appCombinationNew") {
        formats = 2;
      } else if (this.currentTopMenuKey === "appRetailNew") {
        formats = 3;
      } else if (this.currentTopMenuKey === "appPlayNew") {
        formats = 4;
      }

      orderSalesrecord({
        keyword: this.keyword,
        formats, // 业态: 1票务 2组合产品 3零售 4游乐
        page: this.page,
        size: this.size
      })
        .then(
          ({
            records,
            total,
            buyNumSum,
            actualAmountSum,
            totalRefundAmountSum,
            statEnter
          }) => {
            this.statEnter = statEnter || 0;
            this.total = total;
            this.buyNumSum = buyNumSum || 0;
            this.actualAmountSum = actualAmountSum || 0;
            this.totalRefundAmountSum = totalRefundAmountSum || 0;
            records.forEach(item => {
              item.showDetail = false; // 是否显示详情
              item.checked = false;
              item.details = []; // 详情
              // 支付状态
              switch (item.orderStatus) {
                case 1:
                  item.orderStatusText = "未支付";
                  break;
                case 2:
                  item.orderStatusText = "支付中";
                  break;
                case 3:
                  item.orderStatusText = "已支付";
                  break;
                case 4:
                  item.orderStatusText = "待发货（零售）";
                  break;
                case 5:
                  item.orderStatusText = "待收货（零售）";
                  break;
                case 6:
                  item.orderStatusText = "已完成（零售）";
                  break;
                case 7:
                  item.orderStatusText = "已取消";
                  break;
                case 8:
                  item.orderStatusText = "已退单";
                  break;
                case 9:
                  item.orderStatusText = "部分退款";
                  break;
              }
            });
            this.salesrecordList = records;
          }
        )
        .catch(() => {});
    },

    // 显隐明细
    showDetail(idx) {
      let obj = this.salesrecordList[idx];
      // 没有详情和展开的情况下调用接口
      if (obj.details.length <= 0 && !obj.showDetail) {
        // 获取明细
        orderDetailInfoNew({ id: obj.id })
          .then(res => {
            obj.details = res.orderDetailInfoVOS || []; // 产品信息列表
          })
          .catch(() => {});
      }
      obj.showDetail = !obj.showDetail;
      this.salesrecordList[idx] = obj;
    },
    // 条数改变
    sizeChange(current, size) {
      this.page = 1;
      this.size = size;
      this.loadData();
    },
    // 退单校验
    chargeBack(id) {
      this.orderId = id;
      refundOrderCheck({
        mainOrderId: id
      })
        .then(res => {
          this.refundType = 1;
          this.visible = true;
          this.refundInfo = res || {};
        })
        .catch(() => {});
    },
    // 明细退票
    refundDetail(item, row) {
      this.detailItem = row;
      this.orderId = item.id;
      this.refundType = 2;
      refundProductCheck({
        mainOrderId: item.id,
        detailId: row.id
      })
        .then(res => {
          this.visible = true;
          this.refundInfo = res || {};
        })
        .catch(() => {});
    },
    // 完成退款
    refundOk(obj) {
      this.visible = false;
      if (this.refundType === 1) {
        refundOrder({
          mainOrderId: this.orderId,
          remarks: obj.remarks
        })
          .then(res => {
            this.$message.success(
              res.refundFailMsg ? res.refundFailMsg : "退单成功！"
            );
            this.loadOrderSalesrecord(); // 加载数据
          })
          .catch(() => {});
      } else {
        // 产品退款
        refundProduct({
          mainOrderId: this.orderId,
          detailId: this.detailItem.id,
          remarks: obj.remarks
        })
          .then(res => {
            this.$message.success(
              res.refundFailMsg ? res.refundFailMsg : "退款成功！"
            );
            this.loadOrderSalesrecord(); // 加载数据
          })
          .catch(() => {});
      }
    },
    // 重打整单
    batchticketprint(oid) {
      let api = orderBatchticketprint;
      if (this.currentTopMenuKey === "appRetailNew") {
        api = orderWhackretailprint;
      }
      api({ orderId: oid })
        .then(res => {
          if (res) {
            if (this.currentTopMenuKey === "appRetailNew") {
              HardwareService.printSmallTicket(JSON.stringify(res));
            } else {
              HardwareService.printTicket(JSON.stringify(res));
            }
          }
        })
        .catch(() => {});
    },

    // 勾选全部
    checkAll(e) {
      let checked = e.target.checked;
      this.salesrecordList.forEach(item => {
        item.checked = checked;
      });
    },

    // 勾选今日销售
    checkSalesrecord(idx) {
      let obj = this.salesrecordList[idx];
      obj.checked = !obj.checked;
      this.salesrecordList[idx] = obj;
    },

    // 批量重打
    batchPrint() {
      let arr = [];
      this.salesrecordList.forEach(item => {
        if (item.checked) {
          arr.push(item.id);
        }
      });
      if (arr.length) {
        let arrStr = arr.join(",");
        this.batchticketprint(arrStr);
      }
    },

    // 重打产品
    ticketnoPrint(id) {
      orderTicketnoprint({ auxiliaryId: id })
        .then(res => {
          if (res.length) {
            HardwareService.printTicket(JSON.stringify(res));
          }
        })
        .catch(() => {});
    },

    // 收起今日销售
    showSellRecord(name) {
      let item = this.getSettings[name];
      this.$store.commit(`setting/set${this.currentTopMenuKey + name}`, !item);
      if (!item) {
        this.loadData();
      }
    }
  }
};
</script>

<style scoped lang="less">
@import "ticket-order-common";
.disabled {
  color: #c0c4cc !important;
}
.title {
  padding: 0px 20px 10px;
}
.checked-title {
  color: @primary;
  border-bottom: 2px solid @primary;
}
.h-24 {
  height: 24px;
}
</style>
