<!--
  @name: 订单折扣 => 营销活动
-->
<template>
  <div class="yxhd">
    <div class="flex-start pb-1x">
      <!--左边卡片-->
      <ul class="left-card">
        <li
          class="card flex-center flex-col mb-2x"
          :class="{ checked: item.id === businessId }"
          v-for="item in list"
          :key="item.id"
          @click="getDetail(item)"
        >
          <div class="font-l text-1 bold">{{ item.name }}</div>
          <div class="mt-1x gray">{{ item.activityName }}</div>
          <a-icon
            v-show="item.id === businessId"
            class="act-check"
            type="check"
          />
          <span v-show="item.id === businessId" class="act-triangle"></span>
        </li>
      </ul>

      <!-- 新版活动详细 -->
      <div class="right bd round p-2x" v-if="yhInfo.name">
        <!-- 满减活动类型详细 -->
        <div v-show="!yhInfo.ruleType">
          <p class="font-l mb-x">{{ yhInfo.name }}</p>
          <p class="gray">
            有效期：
            {{ yhInfo.validStartDate | date }} -
            {{ yhInfo.validEndDate | date }}
          </p>
          <p class="bd-t dashed mv-1x"></p>
          <p class="mb-1x">
            活动名称：
            <span class="gray">{{ yhInfo.name }}</span>
          </p>
          <div class="mb-1x flex-start">
            <span class="flex-shrink">限产品：</span>
            <div class="slide-wrap gray">
              <span
                style="display: inline-block"
                class="mr-1x mb-1x"
                v-for="item in limitProduct"
                :key="item.products"
              >
                {{ item.productName }}、
              </span>
            </div>
          </div>
        </div>
        <!-- 窗口折扣活动类型详细 -->
        <div class="flex-start" v-show="yhInfo.ruleType && !showWindow">
          <div>
            <!-- 输入身份证键盘 -->
            <keyboard-pro
              v-inputfocus
              v-show="yhInfo.ruleType == 1"
              type="3"
              v-model="idCardNo"
              width="350"
              @ok="enter"
            >
              <template slot="input">
                <a-form-model autocomplete="off">
                  <id-card-input
                    size="large"
                    v-model="idCardNo"
                    @pressEnter="enter"
                  ></id-card-input>
                </a-form-model>
              </template>
            </keyboard-pro>
            <!-- 输入手机号键盘 -->
            <keyboard-pro
              v-show="yhInfo.ruleType == 2"
              type="1"
              width="350"
              v-model="mobile"
              @ok="enter"
            >
              <template v-slot:input="{ confirm }">
                <a-form-model autocomplete="off">
                  <a-input
                    v-inputfocus
                    size="large"
                    :max-length="11"
                    placeholder="请输入手机号"
                    v-model="mobile"
                    allow-clear
                    @pressEnter="confirm()"
                  >
                    <a-icon type="search" class="primary" slot="prefix" />
                  </a-input>
                </a-form-model>
              </template>
            </keyboard-pro>
          </div>
          <div class="ck-right ml-3x">
            <p class="font-l mb-x red">{{ yhInfo.name }}</p>
            <p class="gray mb-2x">
              <span>有效期：</span>
              <span class="gray">
                {{ yhInfo.validStartDate | date }} -
                {{ yhInfo.validEndDate | date }}
              </span>
            </p>
            <p class="gray mb-1x">
              <span>活动说明：</span>
              <span class="gray">
                {{ yhInfo.remark ? yhInfo.remark : "无" }}
              </span>
            </p>
            <div class="mb-1x flex-start">
              <span class="flex-shrink">限产品：</span>
              <div class="slide-wrap gray">
                <span
                  style="display: inline-block"
                  class="mr-1x mb-1x"
                  v-for="item in limitProduct"
                  :key="item.products"
                >
                  {{ item.productName }}、
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- 窗口折扣身份证、手机号查询到的优惠内容显示 -->
        <div v-show="showWindow && yhInfo.ruleType">
          <span class="font-l">{{ showWindowText.name }}</span>
          <p class="mv-3x">
            优惠金额：
            <span class="red">{{ showWindowText.amount | money }}</span>
          </p>
          <a-button type="primary" @click="changeNum">
            更换{{ yhInfo.ruleType == 1 ? "身份证" : "手机号" }}
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getMarketingActivityById,
  WindowActivityList,
  windowIsBooleanDiscount
} from "../../../api/marketing";
import IdCardInput from "../../hardware/IdCardInput.vue";
import KeyboardPro from "@/components/common/KeyboardPro";
import { mapGetters } from "vuex";
import { isIdcard, phoneReg } from "../../../utils/global";

export default {
  components: {
    KeyboardPro,
    IdCardInput
  },
  name: "CheckDiscountActivity",
  props: {
    productList: {
      type: Array,
      default: function() {
        return [];
      }
    },
    // 优惠对象
    preferentialInfo: {
      type: Object,
      default() {
        return null;
      }
    }
  },
  data() {
    return {
      luo: false,
      list: [],
      yhInfo: {},
      idCardNo: "", //后端接口单词拼错了，跟着一起错
      mobile: "",
      businessId: "", //已经选中的优惠券id
      limitProduct: [], // 限制的产品
      showWindow: false, // 显示窗口折扣输入身份证或者手机号后，显示的内容
      showWindowText: {}
    };
  },
  computed: {
    ...mapGetters("common", ["memberId"])
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      let list = this.productList.map(item => {
        return {
          productId: item.productId,
          salePrice: item.sellingAmount,
          count: item.productFlagPO.buyNum,
          merchantId: item.merchantSubScenicId
        };
      });
      WindowActivityList({
        memberId: this.memberId,
        orderChannelId: 6, //6是窗口
        productItems: list
      }).then(res => {
        this.list = res;
        // 回显选中优惠券
        if (
          this.preferentialInfo &&
          this.preferentialInfo.merchantDiscountStr === "D"
        ) {
          let temp = res.find(
            item => item.id === this.preferentialInfo.marketingId
          );
          if (temp) {
            this.getDetail(temp);
          }
        }
      });
    },
    getDetail(item) {
      this.showWindow = false;
      this.showWindowText.name = item.name;
      this.idCardNo = "";
      this.mobile = "";
      if (this.businessId === item.id) {
        this.reset();
        return false;
      }
      this.reset();
      getMarketingActivityById({ id: item.id }).then(res => {
        if (!res.isCouponsAvailable) {
          this.$message.warning("该活动不满足使用条件");
          return;
        }
        this.businessId = item.id;
        this.yhInfo = res;
        this.limitProduct = res.applicableProducts;
        // 回显身份证、手机号
        if (
          this.preferentialInfo &&
          this.preferentialInfo.merchantDiscountStr === "D"
        ) {
          if (res.ruleType == 1) {
            this.idCardNo = this.preferentialInfo.idCardNo;
          } else if (res.ruleType == 2) {
            this.mobile = this.preferentialInfo.idCardNo;
          }
        }
      });
    },
    checkIdCard() {
      let idCardNo = this.idCardNo;
      if (!idCardNo) {
        this.$message.warning("请输入身份证号");
        return false;
      } else {
        //如果为空代表身份证号没问题
        let errMessage = isIdcard(idCardNo);
        if (errMessage === "") {
          return true;
        } else {
          this.$message.warning(errMessage);
          return false;
        }
      }
    },
    checkMobile() {
      let mobile = this.mobile;
      if (!mobile) {
        this.$message.warning("请输入手机号!");
        return false;
      }
      if (mobile && !phoneReg.test(mobile)) {
        this.$message.warning("手机号格式错误!");
        return false;
      } else {
        return true;
      }
    },
    // 回车键
    enter() {
      this.$emit("enter", true);
    },
    async validate() {
      // 选择了活动
      if (this.businessId) {
        // 满减优惠活动
        if (!this.yhInfo.ruleType) {
          return {
            merchantDiscountStr: "D",
            marketingId: this.businessId
          };
        }
        // 窗口折扣活动
        else {
          let code = "";
          let tip = "";
          //1是身份证,2是手机号
          if (this.yhInfo.ruleType == 1) {
            if (!this.checkIdCard()) return false;
            code = this.idCardNo;
            tip = "身份证";
          } else if (this.yhInfo.ruleType == 2) {
            if (!this.checkMobile()) return false;
            code = this.mobile;
            tip = "手机号";
          }
          return await windowIsBooleanDiscount({
            marketingId: this.yhInfo.id, //优惠券编号
            cardOrPhone: code
          })
            .then(res => {
              if (res.isAvailable != 1) {
                this.$message.error(`输入的${tip}，不符合活动使用条件`);
                return false;
              } else {
                return {
                  merchantDiscountStr: "D",
                  marketingId: this.businessId,
                  idCardNo: code
                };
              }
            })
            .catch(() => {
              return false;
            });
        }
      } else {
        // 返回空对象代表没有选择优惠
        return {};
      }
    },
    // 更换手机号或身份证
    changeNum() {
      this.showWindow = false;
    },
    reset() {
      this.businessId = "";
      this.idCardNo = "";
      this.yhInfo = {};
      this.showWindow = false;
    }
  }
};
</script>

<style lang="less" scoped>
/deep/.ant-input.ant-input-lg {
  font-size: 16px;
}
// 隐藏滚动条
::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
  color: transparent;
}
.yxhd {
  .left-card {
    max-height: 340px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 270px;
  }
  .card {
    position: relative;
    width: 250px;
    height: 80px;
    background: #e0e7f2;
    border-radius: 8px;
    padding: 16px;
    cursor: pointer;
    box-shadow: 3px 0 9px 0 rgba(116, 116, 116, 0.26);
    &:hover {
      box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.26);
    }
    &.checked {
      background: #5396fb;
      color: #fff;
      .gray {
        color: #fff;
      }
    }
    .act-check {
      position: absolute;
      top: 30px;
      right: 10px;
      color: #fff;
      font-size: 20px;
    }
    .act-triangle {
      position: absolute;
      top: 30px;
      right: -17px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 0 10px 17px;
      border-color: transparent transparent transparent #5396fb;
    }
  }

  .right {
    margin-left: 43px;
    max-width: 730px;
    .slide-wrap {
      max-height: 120px;
      overflow-x: hidden;
      overflow-y: auto;
    }
    .header {
      position: relative;
      width: 600px;
      height: 120px;
      background: linear-gradient(181deg, #44b8fb, #4d8afb);
      box-shadow: 3px 0 9px 0 rgba(116, 116, 116, 0.26);
      border-radius: 16px;
      font-size: 22px;
      text-align: center;
      color: #fff;
      .money {
        font-size: 28px;
      }

      .circle {
        position: absolute;
        bottom: -16px;
        left: -16px;
        width: 32px;
        z-index: 1;
        height: 32px;
        border-radius: 50%;
        background: #fff;
      }
      .c-right {
        left: auto;
        right: -16px;
      }
    }
    /*信息*/
    .infos {
      padding: 24px;
      box-shadow: 0 0 9px 0 rgba(116, 116, 116, 0.2);
      border-radius: 10px;
      background: #f4f9fc;
      .btn {
        height: 108px;
        width: 100%;
      }
    }
  }
}
</style>
