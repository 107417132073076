<template>
  <div>
    <!-- 计时结束框 -->
    <div class="flex-start align-center">
      <span class="mr-2x bold time-title">计时结束:</span>
      <a-input
        v-model="searchKey"
        v-inputfocus
        placeholder="付款码/会员凭证"
        allow-clear
        @pressEnter="scanEnter"
      >
        <a-icon slot="suffix" type="scan" style="color: #2384e5" />
      </a-input>
    </div>
    <!-- 选中产品弹窗 -->
    <a-modal
      :visible="visible"
      title="请选择要结束的产品"
      width="600px"
      @cancel="visible = false"
    >
      <a-radio-group v-model="productIndex" style="width:100%;">
        <div class="list-box">
          <a-row class="th">
            <a-col :span="24" class="td">产品名称</a-col>
          </a-row>

          <a-row class="tr" v-for="(item, index) in productList" :key="index">
            <a-col :span="20" class="td">{{ item.productName }}</a-col>
            <a-col :span="4" class="td flex-center"
              ><a-radio :value="index"></a-radio
            ></a-col>
          </a-row>
        </div>
      </a-radio-group>
      <template slot="footer">
        <div class="flex-center">
          <a-button
            shape="round"
            style="width: 130px; margin-right: 24px"
            @click="visible = false"
            >取消</a-button
          >
          <a-button
            type="primary"
            style="width: 130px"
            shape="round"
            @click="sureModal"
            >确认</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 结束产品信息弹窗 -->
    <a-modal :visible="showInfos" width="600px" @cancel="showInfos = false">
      <div>
        <a-row class="mb-2x">
          <a-col :span="24" class="td">{{ tempRow.productName }}</a-col>
        </a-row>
        <a-row class="mb-2x">
          <a-col :span="12" class="td"
            >进场时间：{{
              details.timestampStart | date("YYYY-MM-DD hh:mm:ss")
            }}</a-col
          >
          <a-col :span="12" class="td"
            >出场时间：{{
              details.timestampEnd | date("YYYY-MM-DD hh:mm:ss")
            }}</a-col
          >
        </a-row>
        <a-row class="mb-2x">
          <a-col :span="12" class="td">游玩时间：{{ details.playTime }}</a-col>
          <a-col :span="12" class="td"
            >押金：{{ details.totalAmount | money() }}</a-col
          >
        </a-row>
        <a-row class="mb-2x">
          <a-col :span="12" class="td"
            >总消费：{{ details.depositRefundTotalAmount | money() }}</a-col
          >
          <a-col :span="12" class="td">状态：已核销</a-col>
        </a-row>
        <a-row>
          <a-col :span="12" class="td"
            >{{ details.status === 2 ? "应退" : "应付" }}金额：{{
              Math.abs(details.depositRefundTotalAmount - details.totalAmount)
                | money()
            }}</a-col
          >
          <a-col :span="12" class="td"
            >支付方式：{{ tempRow.payWayName }}</a-col
          >
        </a-row>
      </div>
      <template slot="footer">
        <div class="flex-center">
          <a-button
            shape="round"
            style="width: 130px; margin-right: 24px"
            @click="showInfos = false"
            >取消</a-button
          >
          <a-button
            type="primary"
            style="width: 130px"
            shape="round"
            @click="changeStatus"
            >{{ details.status === 3 ? "去付款" : "确认" }}</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import {
  quickOrderProductWrittenOffList,
  quickOrderProductDepositAmount,
  updateQuickOrderWriteOffStatus,
  timingFinishRefund
} from "../../../api/order";
import moment from "moment";
export default {
  name: "TimeEnd",
  data() {
    return {
      moment,
      visible: false, // 选择产品弹窗
      productIndex: 0, // 选中产品
      productList: [], // 产品列表
      tempRow: [], // 缓存数据
      searchKey: "", // 输入框的值
      showInfos: false, // 要结束产品的信息
      details: {} // 详情数据
    };
  },
  methods: {
    // 确认按钮
    changeStatus() {
      if (this.details.status === 1) {
        this.changeWrittenStatus();
      } else if (this.details.status === 2) {
        this.refund();
      } else if (this.details.status === 3) {
        this.visible = false;
        this.showInfos = false;
        let arr = {
          id: this.tempRow.id,
          writeOffStatus: 3,
          remark: this.tempRow.productName,
          money:
            (this.details.depositRefundTotalAmount - this.details.totalAmount) /
            100
        };
        this.$emit("pay-only", arr);
      }
    },
    // 退款
    refund() {
      timingFinishRefund(
        {
          checkDetailId: this.tempRow.id,
          refundAmount:
            this.details.totalAmount - this.details.depositRefundTotalAmount
        },
        { loading: false }
      )
        .then(() => {
          this.changeWrittenStatus();
        })
        .catch();
    },
    // 修改计时状态
    changeWrittenStatus() {
      updateQuickOrderWriteOffStatus({
        id: this.tempRow.id,
        writeOffStatus: 3,
        updateTime: moment().valueOf()
      })
        .then(() => {
          this.showInfos = false;
          this.scanEnter();
        })
        .catch();
    },
    // 记时结束回车
    scanEnter() {
      if (this.searchKey) {
        quickOrderProductWrittenOffList({
          identifyCode: this.searchKey
        })
          .then(res => {
            this.productList = res;
            this.visible = true;
          })
          .catch();
      }
    },
    // 选择产品确定
    sureModal() {
      if (this.productList.length) {
        const data = this.productList[this.productIndex];
        this.tempRow = data;
        quickOrderProductDepositAmount({
          productId: data.productId,
          totalAmount: data.receivedAmount,
          timestampStart: data.writeOffTime,
          timestampEnd: moment().valueOf(),
          spuRuleSnapshotId: data.spuRuleSnapshotId
        })
          .then(res => {
            let playTime = res.timestampEnd - res.timestampStart;
            res.playTime = this.showTime(playTime);
            let status = 1; // 状态 1不退不补 2 退款 3 补钱
            let money = res.totalAmount - res.depositRefundTotalAmount; // 押金减去相关金额
            // res.depositRefund 1.多退少补 2.只退不补
            if (res.depositRefund === 1 && money > 0) {
              status = 2;
            } else if (res.depositRefund === 1 && money < 0) {
              status = 3;
            } else if (res.depositRefund === 2 && money > 0) {
              status = 2;
            }
            res.status = status;
            this.details = res;
            this.showInfos = true;
          })
          .catch();
      }
    },
    // 计算时分秒
    showTime(val) {
      val = val / 1000;
      if (val < 60) {
        return val + "秒";
      } else {
        let min_total = Math.floor(val / 60); // 分钟
        let sec = Math.floor(val % 60); // 余秒
        if (min_total < 60) {
          return min_total + "分钟" + sec + "秒";
        } else {
          let hour_total = Math.floor(min_total / 60); // 小时数
          let min = Math.floor(min_total % 60); // 余分钟
          return hour_total + "小时" + min + "分钟" + sec + "秒";
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "../ticket-order-common";
.time-title {
  width: 85px;
}
</style>
