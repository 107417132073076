<template>
  <div>
    <b>打印方式：</b>
    <template v-if="!cartId">
      <span class="disabled">
        {{ types[printType - 1].name }}
        <a-icon :type="visible ? 'up' : 'down'" />
      </span>
    </template>
    <a-popover v-else v-model="visible" trigger="click" placement="top">
      <template slot="content">
        <div
          class="print-way-item flex-between"
          v-for="item in types"
          :key="item.value"
          :value="item.value"
        >
          <div
            :class="{ blue: printType === item.value }"
            class="pointer"
            @click="changePrintType(item, 0)"
          >
            {{ item.name }}
          </div>
          <template v-if="isDefaultPrintType == item.value">
            <span class="pointer">已设默认</span>
          </template>
          <template v-else>
            <div
              v-if="item.value !== 3"
              class="pointer primary"
              @click="changePrintType(item, 1)"
            >
              设为默认
            </div>
          </template>
        </div>
      </template>
      <span class="pointer" :class="{ disabled: !cartId }">
        {{ types[printType - 1].name }}
        <a-icon :type="visible ? 'up' : 'down'" />
      </span>
    </a-popover>
    <!--自定义打印产品规则列表-->
    <a-modal
      v-model="showList"
      title="产品列表"
      width="1000px"
      :mask-closable="false"
      @ok="showList = false"
    >
      <a-table
        :columns="columns"
        :data-source="productList"
        row-key="productId"
        :pagination="false"
      >
        <template slot-scope="row" slot="sellingAmount">
          {{ row.sellingAmount | money }}
        </template>
        <template slot-scope="row" slot="total">
          {{ row.total | money }}
        </template>
        <template slot-scope="row" slot="action">
          <a-button @click="editPrint(row)" type="link">修改打印规则</a-button>
        </template>
      </a-table>
    </a-modal>

    <!-- 打印规则弹窗 -->
    <a-modal
      v-model="showCustom"
      title="修改打印规则"
      :mask-closable="false"
      @ok="handleOk"
    >
      <a-form-model
        ref="ruleForm"
        :model="printForm"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item ref="name" prop="name">
          <a-radio-group v-model="printForm.cartPrintType">
            <a-radio
              class="radio-style"
              :value="item.value"
              v-for="item in types"
              :key="item.value"
            >
              {{ item.name }}
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item
          label="本次打印票数"
          v-if="printForm.cartPrintType === 3"
        >
          <a-input-number
            v-inputfocus
            class="print-num"
            :min="1"
            :max="tempRow.buyNum"
            :step="1"
            v-model="printForm.printNum"
            placeholder="请输入"
            allow-clear
            addon-after="张"
            @blur="changeNum"
          ></a-input-number>
          <span class="ml-1x">张</span>
        </a-form-model-item>
        <a-form-model-item
          label="每张票支持核销次数"
          v-if="printForm.cartPrintType === 3"
        >
          <a-input-number
            v-inputfocus
            v-for="(item, index) in printForm.times"
            :key="index"
            :min="0"
            :max="item.maxTotalNum"
            :step="1"
            class="print-time"
            v-model="item.num"
            placeholder="请输入"
            addon-after="次"
            allow-clear
            @blur="blurWriteOff"
            @focus="focusNum(item, index)"
          ></a-input-number>
        </a-form-model-item>
      </a-form-model>
      <!--当前共100个成人票，本次打印2张票，包含60个成人票，剩余40个成人票。-->
      <p v-if="printForm.cartPrintType === 3">
        提示：当前共<span class="red bold mh-1x">{{ tempRow.buyNum }}</span
        >张{{ tempRow.productName }}，本次打印<span class="red bold mh-1x">
          {{ printForm.printNum }}</span
        >张票，包含<span class="red bold mh-1x">{{ cludeNum }}</span
        >张{{ tempRow.productName }}，剩余<span class="red bold mh-1x">{{
          remainingNum
        }}</span
        >张{{ tempRow.productName }}。
      </p>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { commonPrintProductList, commonPrintWay } from "../../../api/cart";

export default {
  name: "PrintWay",

  props: {
    cartId: {
      type: Number,
      default: 0
    },
    productId: {
      type: String,
      default: ""
    },
    productName: {
      type: String,
      default: ""
    },
    printNum: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      columns: [
        {
          title: "产品名称",
          key: "productName",
          dataIndex: "productName"
        },
        {
          title: "单价",
          key: "sellingAmount",
          scopedSlots: { customRender: "sellingAmount" }
        },
        {
          title: "数量",
          key: "buyNum",
          dataIndex: "buyNum"
        },
        {
          title: "小计",
          key: "total",
          scopedSlots: { customRender: "total" }
        },
        {
          title: "打印规则",
          key: "printRule",
          dataIndex: "printRule"
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" }
        }
      ],
      types: [
        {
          name: "一个产品一票",
          value: 1
        },
        {
          name: "一种产品一票",
          value: 2
        },
        {
          name: "自定义规则",
          value: 3
        }
      ],
      showList: false,
      productList: [], // 产品列表
      labelCol: { span: 7 },
      wrapperCol: { span: 17 },
      printType: 1, // 打印方式
      showCustom: false,
      printForm: {
        cartPrintType: 1, //打印规则：1后台打印规则，2窗口打印规则，3自定义规则
        printNum: 1, //本次打印数量
        times: [{ num: 1, maxTotalNum: 1 }] //每张票支持核销次数，数字数组
      },
      rules: {},
      cludeNum: 0, // 包含票数
      remainingNum: 0, // 剩余票数
      tempRow: {
        buyNum: 0
      },
      visible: false, // 是否显示打印方式弹窗
      isDefaultPrintType: 1 // 默认打印方式
    };
  },
  computed: {
    ...mapGetters({
      // 默认打印方式
      getSettings: "setting/getSettings"
    }),
    // 头部激活的currentTopMenuKey
    currentTopMenuKey() {
      return this.$store.state.common.currentTopMenuKey;
    }
  },
  created() {
    // 如果是组合产品，则存在一子产品一票规则
    if (this.$store.state.common.currentTopMenuKey === "appCombinationNew") {
      this.types.splice(2, 0, {
        name: "一子产品一票",
        value: 4
      });
    }
    if (this.getSettings.PrintType) {
      this.isDefaultPrintType = this.getSettings.PrintType; // 默认打印方式
      this.printType = this.getSettings.PrintType; // 打印方式
    }
  },
  methods: {
    // 获取焦点 计算可输入最大产品数
    focusNum(item, index) {
      let num = 0;
      this.printForm.times.forEach((val, key) => {
        if (index !== key) {
          num += val.num;
        }
      });
      // 本张票最大输数 = 购买数量 - 其它框输入的数量
      item.maxTotalNum = this.tempRow.buyNum - num;
    },
    //type 0选择打印方式 1设置默认打印方式
    changePrintType(item, type) {
      this.printType = item.value;
      this.visible = false;
      if (item.value !== 3 && type === 1) {
        this.isDefaultPrintType = item.value;
        localStorage.setItem(
          `${this.currentTopMenuKey + "PrintType"}`,
          this.printType
        );
        this.$store.commit(
          `setting/set${this.currentTopMenuKey + "PrintType"}`,
          this.printType
        );
      }
      this.change();
    },
    // 打印方式切换
    change() {
      if (this.printType === 3) {
        this.loadProductList();
      } else {
        this.updatePrint();
      }
    },
    // 加载产品列表
    loadProductList() {
      commonPrintProductList({
        id: this.cartId
      })
        .then(({ productList }) => {
          productList.forEach(item => {
            let typeName = "一种产品一票";
            if (item.printSettingPO) {
              const type = this.types.find(
                temp => temp.value === item.printSettingPO.cartPrintType
              );
              typeName = type.name;
            }
            item.buyNum = item.productFlagPO.buyNum;
            item.printRule = typeName;
            item.total = item.sellingAmount * item.buyNum;
          });
          this.productList = productList;
          this.showList = true;
        })
        .catch(() => {});
    },
    // 更新打印规则
    updatePrint() {
      commonPrintWay({
        cartId: this.cartId,
        cartPrintType: this.printType
      })
        .then(() => {
          this.$message.success("修改成功");
        })
        .catch(() => {});
    },
    //计算核销次数之和及剩余核销次数
    calculateNum() {
      let totalNum = 0;
      for (let i = 0; i < this.printForm.times.length; i++) {
        totalNum += this.printForm.times[i].num;
      }
      this.cludeNum = totalNum;
      this.remainingNum = this.tempRow.buyNum - totalNum;
    },
    //本次打印票数改变
    changeNum() {
      if (this.printForm.printNum > this.tempRow.buyNum) {
        this.$message.error("打印的票数不能大于产品数量");
      } else {
        this.printForm.times = [];
        for (let i = 0; i < this.printForm.printNum; i++) {
          let num = 1;
          this.printForm.times.push({
            num,
            maxTotalNum: num
          });
        }
      }
      this.calculateNum();
    },
    //核销次数失去焦点
    blurWriteOff() {
      this.calculateNum();
    },
    //确定保存按钮
    handleOk() {
      let writeOffNum = [];
      // 如果是自定义规则，则需要校验
      if (!this.printForm.printNum) {
        this.$message.error("请输入本次打印票数");
        return;
      } else if (this.printType === 3) {
        this.printForm.times.forEach(item => {
          writeOffNum.push(item.num);
        });
        if (this.printForm.printNum > this.tempRow.buyNum) {
          this.$message.error("打印的票数不能大于产品数量");
          return;
        } else if (isNaN(this.cludeNum)) {
          this.$message.error("请正确填写核销次数");
          return;
        } else if (this.cludeNum > this.tempRow.buyNum) {
          this.$message.error("每张票支持核销次数的总和不能大于产品数量");
          return;
        } else if (writeOffNum.some(item => !item)) {
          this.$message.error("请填写每张票支持核销次数");
          return;
        }
      }
      commonPrintWay({
        cartId: this.cartId,
        productId: this.tempRow.productId,
        cartPrintType: this.printForm.cartPrintType,
        printNum: this.printForm.printNum,
        writeOffNum
      })
        .then(() => {
          this.showCustom = false;
          this.loadProductList();
        })
        .catch();
    },
    // 修改打印规则
    editPrint(row) {
      this.tempRow = row;
      // 自定义规则回显数据
      if (row.printSettingPO) {
        this.printForm.cartPrintType = row.printSettingPO.cartPrintType;
        this.printForm.printNum = row.printSettingPO.printNum || 1;
        this.printForm.writeOffNum = row.printSettingPO.writeOffNum || [1];
      }
      this.calculateNum();
      this.showCustom = true;
    }
  }
};
</script>

<style scoped>
.radio-style {
  display: block;
  height: 30px;
  line-height: 30px;
}
.print-way-item {
  width: 228px;
  line-height: 40px;
}
</style>
